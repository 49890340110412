import React, { useRef } from "react";
import classNames from "classname";

const polishLetters = {
	ą: "a",
	ć: "c",
	ę: "e",
	ł: "l",
	ń: "n",
	ó: "o",
	ś: "s",
	ź: "z",
	ż: "z",
};

const transformIdWithFormat = (text) => {
	let transformedText = text.toLowerCase();
	transformedText = [...transformedText].map((char) => polishLetters[char] || char).join("");
	return transformedText
		.replace(/\s+/g, "-")
		.replace(/[!?.,;:]+/g, "")
		.replace(/-+/g, "-");
};

const LinkTo = ({ children, to, format, offset = -124, block, className, ...props }) => {
	const generatedId =
		to || (format && typeof children === "string" ? transformIdWithFormat(children) : "");
	const linkRef = useRef(null);

	const handleScroll = (e) => {
		e.preventDefault();
		const targetElement = document.getElementById(generatedId);
		if (!targetElement) return;
		const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY;
		const finalPosition = elementPosition + offset;
		window.scrollTo({
			top: finalPosition,
			behavior: "smooth",
		});
	};

	return (
		<span className={classNames("link-to", { block })}>
			<a
				href={"#" + generatedId}
				className={classNames(className)}
				// aria-label={props["aria-label"] || `Przejdź do sekcji - ${children}`}
				onClick={handleScroll}
				ref={linkRef}
				{...props}
			>
				{children}
			</a>
		</span>
	);
};

export default LinkTo;
