import React, { createContext, useState, useContext } from "react";

const AccordionContext = createContext({});

export function Accordion({
	children,
	className = "",
	allowMultipleOpen,
	allowMultipleExpanded,
	preExpanded = [],
}) {
	const allowMultiple = allowMultipleOpen || allowMultipleExpanded || false;
	const initialIndices = preExpanded.map((item) =>
		typeof item === "string" ? parseInt(item, 10) : item,
	);
	const [expandedIndices, setExpandedIndices] = useState(initialIndices);

	const toggleItem = (index) => {
		if (expandedIndices.includes(index)) {
			setExpandedIndices(expandedIndices.filter((i) => i !== index));
		} else {
			if (allowMultiple) {
				setExpandedIndices([...expandedIndices, index]);
			} else {
				setExpandedIndices([index]);
			}
		}
	};

	return (
		<AccordionContext.Provider value={{ expandedIndices, toggleItem }}>
			<div className={className}>
				{React.Children.map(children, (child, idx) => {
					if (React.isValidElement(child)) {
						return React.cloneElement(child, { itemIndex: idx });
					}
					return child;
				})}
			</div>
		</AccordionContext.Provider>
	);
}

export function AccordionItem({ children }) {
	return <div className="accordion__item">{children}</div>;
}

export function AccordionItemHeading({ children }) {
	return <div className="accordion__heading">{children}</div>;
}

export function AccordionItemButton({ children, itemIndex, ...rest }) {
	const { expandedIndices, toggleItem } = useContext(AccordionContext);
	const isExpanded = expandedIndices.includes(itemIndex);
	const buttonId = `accordion__button-${itemIndex}`;
	const panelId = `accordion__panel-${itemIndex}`;

	const handleClick = () => {
		toggleItem(itemIndex);
	};

	return (
		<button
			id={buttonId}
			className="accordion__action"
			onClick={handleClick}
			aria-expanded={isExpanded}
			aria-controls={panelId}
			{...rest}
		>
			{children}
		</button>
	);
}

export function AccordionItemPanel({ children, itemIndex }) {
	const { expandedIndices } = useContext(AccordionContext);
	const isExpanded = expandedIndices.includes(itemIndex);
	const headingId = `accordion__button-${itemIndex}`;
	const panelId = `accordion__panel-${itemIndex}`;

	return (
		<div
			id={panelId}
			className="accordion__panel"
			role="region"
			aria-labelledby={headingId}
			hidden={!isExpanded}
		>
			{children}
		</div>
	);
}

export { AccordionContext };
