import React, { useMemo, useContext } from "react";
import { useIntl } from "react-intl";
import dynamic from "next/dynamic";
import Script from "next/script";
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
	AccordionContext,
} from "./Accordion";
import AccordionVariables from "../variables/variables.json";

const TranslatedMessageFAQ = dynamic(() => import("./TranslatedMessageFAQ"), {
	ssr: false,
});

function FAQExample(props) {
	const intl = useIntl();
	const propsMiasto = "PRZYJAZNEDEKLARACJE";
	const propsPodatek = props.section;

	const translatedQuestions = useMemo(() => {
		return AccordionVariables.miasta[propsMiasto].FAQ[propsPodatek].map((faq) => ({
			title: intl.formatMessage({ id: faq.title }),
			tekst: intl.formatMessage({ id: faq.tekst }),
		}));
	}, [intl, propsMiasto, propsPodatek]);

	const schemaData = useMemo(() => {
		const questions = translatedQuestions.map((faq) => ({
			"@type": "Question",
			name: faq.title,
			acceptedAnswer: {
				"@type": "Answer",
				text: faq.tekst,
			},
		}));
		return [
			{
				"@context": "https://schema.org",
				"@type": "FAQPage",
				mainEntity: questions,
			},
		];
	}, [translatedQuestions]);

	const AccordionButtonLabel = ({ itemIndex }) => {
		const { expandedIndices } = useContext(AccordionContext);
		return expandedIndices.includes(itemIndex) ? "Zwiń odpowiedź" : "Rozwiń odpowiedź";
	};

	return (
		<>
			<Accordion className="accordion-template--secondary" allowMultipleOpen={false}>
				{translatedQuestions.map((faq, index) => (
					<AccordionItem key={index}>
						<AccordionItemHeading>
							<h3 className="accordion__title" id={`heading-${index}`}>
								{faq.title}
							</h3>
							<AccordionItemButton itemIndex={index}>
								<AccordionButtonLabel itemIndex={index} />
							</AccordionItemButton>
							{/* {props.number && <span className="accordion__number">{index + 1}</span>} */}
						</AccordionItemHeading>
						<AccordionItemPanel itemIndex={index}>
							<TranslatedMessageFAQ
								id={AccordionVariables.miasta[propsMiasto].FAQ[propsPodatek][index].tekst}
							/>
						</AccordionItemPanel>
					</AccordionItem>
				))}
			</Accordion>
			<Script
				id="accordion__script"
				type="application/ld+json"
				dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
			/>
		</>
	);
}

export default FAQExample;
